import * as React from 'react';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link'; 
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid'; 
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import firebase from './firebase'; 

const baseUrl = process.env.PUBLIC_URL;

const drawerWidth = 240;

const navItems = [
  { label: 'FLK1', route: '/FLK1' },
  { label: 'FLK2', route: '/FLK2' },
  { label: 'FAQ', route: '/FAQ' },
  
];
const navItemsFirstRow = [
  { label: 'FLK1', route: '/FLK1' },
  { label: 'FLK2', route: '/FLK2' },
  
];
const navItemsSecondRow = [
  { label: 'FAQ', route: '/FAQ' },
  
];

// Customize theme breakpoints
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 1120,
      md: 1278,
      lg: 1280,
      xl: 1920,
    },
  },
});

function NavBar(props) {

  const [userId, setUserId] = useState('');

  useEffect(()=>{
    firebase.auth().onAuthStateChanged((user)=>{
      if(user){
        setUserId(user.uid);
      }else{
        setUserId('');
      }
    })
}, [userId]);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Link component={RouterLink} href="/" color="inherit" underline="none" cursor="pointer">
        <Typography variant="h6" sx={{ my: 2 }}>
          Law School Compass
        </Typography>
      </Link>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding style={{width:'100%'}}>
            <RouterLink to={item.route} style={{ textDecoration: 'none', color: 'inherit', width:'100%' }}>
              <ListItemButton sx={{ textAlign: 'center'}}>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </RouterLink>
          </ListItem>
        ))}
        
          {!userId ? (
            <div>
                              <ListItem key='login' disablePadding style={{width:'100%'}}>
                              <RouterLink to={'/Login'} style={{ textDecoration: 'none', color: 'inherit', width:'100%' }}>
                                <ListItemButton sx={{ textAlign: 'center'}}>
                                  <ListItemText primary={'Log In'} />
                                </ListItemButton>
                              </RouterLink>
                            </ListItem>
          <ListItem key='signup' disablePadding style={{width:'100%'}}>
            <RouterLink to={'/Signup'} style={{ textDecoration: 'none', color: 'inherit', width:'100%' }}>
              <ListItemButton sx={{ textAlign: 'center'}}>
                <ListItemText primary={'Sign Up'} />
              </ListItemButton>
            </RouterLink>
          </ListItem>
          </div>
          ) : (
            <div>
                        <ListItem key='dashboard' disablePadding style={{width:'100%'}}>
            <Link component={RouterLink} to={'/Dashboard'} style={{ textDecoration: 'none', color: 'inherit', width:'100%' }}>
              <ListItemButton sx={{ textAlign: 'center'}}>
                <ListItemText primary={'Dashboard'} />
              </ListItemButton>
            </Link>
          </ListItem>
            
            <ListItem key='logOut' disablePadding style={{width:'100%'}}>
              <ListItemButton sx={{ textAlign: 'center'}} onClick={()=>firebase.auth().signOut()} style={{ textDecoration: 'none', color: 'inherit', width:'100%' }}>
                <ListItemText primary={'Log Out'} />
              </ListItemButton>
          </ListItem>
          </div>
          )
}
      </List>
    </Box>
  );

  const handleAccountMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAccountClose = () => {
    setAnchorEl(null);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={theme}> {/* Apply custom theme */}
      <Box sx={{ display: 'flex', marginBottom: 2 }}>
        <CssBaseline />
        <AppBar component="nav" position="sticky" sx={{backgroundColor:"#004AAD"}}> 
          <Toolbar>
            <Grid container spacing={0}>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ height:'100%',display: { sm: 'none', xs:'block' }, alignItems: 'center', justifyContent: 'center', }}
              
            >
              <MenuIcon sx={{fontSize:'40px',}}/>
            </IconButton>
                <Typography variant="h6" component="div" sx={{ display: {xs:'none', sm:'flex'}, alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <Link component={RouterLink} to="/" color="inherit" underline="none" cursor="pointer" style={{ fontSize: "30px", padding:7}}>
                    Law School Compass
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <Link component={RouterLink} to="/" color="inherit" underline="none" cursor="pointer">
                    <img src={`${baseUrl}/logo_rect.jpg`} alt="Law School Compass - Est. 2024" style={{maxHeight:"100%", maxWidth: "310px",width:"auto", margin:"auto"}}/>
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} >
                <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                
                  <Box sx={{ display: { xs: 'none', sm: 'block' }, textAlign:"center", userSelect:'none' }}>
                  
                    {/* {navItems.map((item) => (
                      <Button key={item.label} sx={{ color: '#fff', fontSize:"17px", margineLeft:"5px", marginRight:"5px"}} component={RouterLink} to={item.route}>
                        {item.label}
                      </Button>
                    ))} */}
                    
                                        {navItemsFirstRow.map((item) => (
                      <Button key={item.label} sx={{ color: '#fff', fontSize:"17px", margineLeft:"5px", marginRight:"5px", ':hover':{bgcolor:'black', border:'2px solid white'}}} component={RouterLink} to={item.route}>
                        {item.label}
                      </Button>
                    ))}
                    <br/>
                                        {navItemsSecondRow.map((item) => (
                      <Button key={item.label} sx={{ color: '#fff', fontSize:"17px", margineLeft:"5px", marginRight:"5px", ':hover':{bgcolor:'black', border:'2px solid white'}}} component={RouterLink} to={item.route}>
                        {item.label}
                      </Button>
                    ))}
              <Button
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleAccountMenu}
                color='inherit'
                sx={{ fontSize: "17px", ':hover':{bgcolor:'black', border:'2px solid white'} }}
              >
                Account
                <ExpandMoreIcon />
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleAccountClose}
              >
                {!userId ? (
                  <div>
                <MenuItem
                  component={RouterLink} to={'/Login'}
                >
                  Log In
                </MenuItem>
                <MenuItem
                  component={RouterLink} to={'/Signup'}
                >
                  Sign Up
                </MenuItem>
              
              </div>
                ) : (
                  <div>
                <MenuItem
                  component={RouterLink} to={'/Dashboard'}
                >
                  Dashboard
                </MenuItem>
                <MenuItem
                  onClick={()=>firebase.auth().signOut()}
                >
                  Log Out
                </MenuItem>
              
              </div>
                )
              }
              </Menu>
                  </Box>
                  
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            <Link component={RouterLink} to="/" color="inherit" underline="none" cursor="pointer">
              <img src={`${baseUrl}/logo.jpg`} alt="Law School Compass - Est. 2024" style={{width:"100%", height:"auto"}}/>
            </Link>
            {drawer}
          </Drawer>
        </nav>
      </Box>
    </ThemeProvider>
  );
}

export default NavBar;
