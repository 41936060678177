import * as React from 'react';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Import the arrow icon
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import firebase from './firebase';

const baseUrl = process.env.PUBLIC_URL;

const drawerWidth = 240;

const navItems = [
  { label: 'SQE1', route: '/SQE1' },
  { label: 'SQE2', route: '/SQE2' },
  { label: 'FAQ', route: '/FAQ' },
  
];


const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 1100,
      md: 1278,
      lg: 1280,
      xl: 1920,
    },
  },
});

function RevampNavBar(props) {
  const [userId, setUserId] = useState('');

  useEffect(()=>{
    firebase.auth().onAuthStateChanged((user)=>{
      if(user){
        setUserId(user.uid);
      }else{
        setUserId('');
      }
    })
}, [userId]);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Link component={RouterLink} href="/" color="inherit" underline="none" cursor="pointer">
        <Typography variant="h6" sx={{ my: 2 }}>
          Law School Compass
        </Typography>
      </Link>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding style={{width:'100%'}}>
            <RouterLink to={item.route} style={{ textDecoration: 'none', color: 'inherit', width:'100%' }}>
              <ListItemButton sx={{ textAlign: 'center'}}>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </RouterLink>
          </ListItem>
        ))}
        
          {!userId ? (
            <div>
                              <ListItem key='login' disablePadding style={{width:'100%'}}>
                              <RouterLink to={'/Login'} style={{ textDecoration: 'none', color: 'inherit', width:'100%' }}>
                                <ListItemButton sx={{ textAlign: 'center'}}>
                                  <ListItemText primary={'Log In'} />
                                </ListItemButton>
                              </RouterLink>
                            </ListItem>
          <ListItem key='signup' disablePadding style={{width:'100%'}}>
            <RouterLink to={'/Signup'} style={{ textDecoration: 'none', color: 'inherit', width:'100%' }}>
              <ListItemButton sx={{ textAlign: 'center'}}>
                <ListItemText primary={'Sign Up'} />
              </ListItemButton>
            </RouterLink>
          </ListItem>
          </div>
          ) : (
            <div>
                        <ListItem key='dashboard' disablePadding style={{width:'100%'}}>
            <Link component={RouterLink} to={'/Dashboard'} style={{ textDecoration: 'none', color: 'inherit', width:'100%' }}>
              <ListItemButton sx={{ textAlign: 'center'}}>
                <ListItemText primary={'Dashboard'} />
              </ListItemButton>
            </Link>
          </ListItem>
            
            <ListItem key='logOut' disablePadding style={{width:'100%'}}>
              <ListItemButton sx={{ textAlign: 'center'}} onClick={()=>firebase.auth().signOut()} style={{ textDecoration: 'none', color: 'inherit', width:'100%' }}>
                <ListItemText primary={'Log Out'} />
              </ListItemButton>
          </ListItem>
          </div>
          )
}
      </List>
    </Box>
  );

  const handleAccountMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAccountClose = () => {
    setAnchorEl(null);
  };

  const container = window !== undefined ? () => window().document.body : undefined;


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="sticky" sx={{ backgroundColor: "#004AAD" }}>
        <Toolbar>
          {/* Left Section with Logo and Title */}
          <Grid container alignItems="center">
          <Grid 
  item 
  xs={12} 
  sm={5} 
  sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: { xs: 'center', sm: 'flex-start' } 
  }}
>
  <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="start"
    onClick={handleDrawerToggle}
    sx={{ 
      display: { sm: 'none' }, 
      height: '100%',
    }}
  >
    <MenuIcon sx={{ fontSize: '40px' }} />
  </IconButton>
  
  <Link component={RouterLink} to="/" color="inherit" underline="none" sx={{ mr: { sm: 2, xs: 0 } }}>
    <img 
      src={`${baseUrl}/logo_rect.jpg`} 
      alt="Law School Compass - Est. 2024" 
      style={{ maxHeight: "100%", maxWidth: "260px" }} 
    />
  </Link>
  
  <Typography 
    variant="h6" 
    sx={{ 
      display: { xs: 'none', sm: 'block' }, // Hide text on xs screens
      fontWeight: 'bold', 
      color: 'white', 
      fontSize: "27px" 
    }}
  >
    Law School Compass
  </Typography>
</Grid>


            {/* Middle Section with Navigation Links */}
            <Grid item xs={5} sx={{ display: {xs:'none',sm:'flex'}, justifyContent: 'center', gap: 2, textAlign: "center" }}>
            {['SQE1', 'SQE2', 'Soft Skills', 'FAQ', 'Contact Us'].map((label) => (
    <Button
      key={label}
      component={label === 'Contact Us' ? 'a' : RouterLink}
      href={label === 'Contact Us' ? 'mailto:info.admin@lawschoolcompass.com' : undefined}
      to={label !== 'Contact Us' ? `/${label.replace(' ', '')}` : undefined}
      sx={{
        color: 'white',
        fontSize: '16px',
        ':hover': { bgcolor: 'black', border: '2px solid white' },
      }}
    >
      {label}
    </Button>
  ))}
            </Grid>

            {/* Right Section with Log In/Sign Up or Dashboard/Log Out */}
            <Grid item xs={2} sx={{ display: {xs:'none',sm:'flex'}, justifyContent: 'flex-end', gap: 1, textAlign: "center" }}>
              {userId ? (
                <>
                  <Button
                    component={RouterLink}
                    to="/Dashboard"
                    sx={{
                      bgcolor: 'white',
                      color: '#004AAD',
                      borderRadius: '20px',
                      px: 2,
                      ':hover': { bgcolor: 'black', color: 'white' },
                    }}
                  >
                    Dashboard
                  </Button>
                  <Button
                    onClick={() => firebase.auth().signOut()}
                    sx={{
                      bgcolor: 'white',
                      color: '#004AAD',
                      borderRadius: '20px',
                      px: 2,
                      ':hover': { bgcolor: 'black', color: 'white' },
                    }}
                  >
                    Log Out
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    component={RouterLink}
                    to="/Login"
                    sx={{
                      bgcolor: 'white',
                      color: '#004AAD',
                      borderRadius: '20px',
                      px: 2,
                      ':hover': { bgcolor: 'black', color: 'white' },
                    }}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Log In
                  </Button>
                  <Button
                    component={RouterLink}
                    to="/Signup"
                    sx={{
                      bgcolor: 'white',
                      color: '#004AAD',
                      borderRadius: '20px',
                      px: 2,
                      ':hover': { bgcolor: 'black', color: 'white' },
                    }}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Sign Up
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            <Link component={RouterLink} to="/" color="inherit" underline="none" cursor="pointer">
              <img src={`${baseUrl}/logo.jpg`} alt="Law School Compass - Est. 2024" style={{width:"100%", height:"auto"}}/>
            </Link>
            {drawer}
          </Drawer>
        </nav>
    </ThemeProvider>
  );
}

export default RevampNavBar;
