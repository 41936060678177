import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './App.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Paper, CardMedia, Divider } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import sqe_flk1 from './img/SQE_FLK1.jpg';
import sqe_flk2 from './img/SQE_FLK2.jpg';
import Grid from '@mui/material/Grid';
import llb from './img/LLB.jpg';
import CountdownModal from './Countdown';
import PoliciesSnackBar from './PoliciesSnackBar';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'; // Import icon for the reviews
import girlStudyingPolygon from './girl_studying_polygon.png'; // Replace with the path to your image
import RevampNavBar from './RevampNavBar';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SavingsIcon from '@mui/icons-material/Savings';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import VoiceflowChat from './VoiceFlowChat';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}




const Home = () => {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
   setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  
  return (
    <div className="App" sx={{ bgcolor: '#0F1214', color: 'white'}}>
      <RevampNavBar/>
      <Box >
            {/* Section 1 */}
            {/* <Box sx={{ bgcolor: '#004AAD', color: 'white', py: 4 }}> */}
            <Box sx={{ bgcolor: '#0F1214', color: 'white', py: 4 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={7} sx={{ textAlign: { xs: 'center', md: 'center' }, p: 2,}}>
                    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', m: 2 }}>
                            100% affordable, flexible support for the Solicitors Qualification Exam (SQE)
                        </Typography>
                        <Typography variant="h6" component="h1" sx={{ fontWeight: 'bold', m: 2 }}>
                            Take the next step in your legal career from the comfort of your own home!
                        </Typography>
                        <Button
                    component={RouterLink}
                    size="large"
                    to="/Signup"
                    sx={{
                      bgcolor: 'white',
                      color: '#004AAD',
                      borderRadius: '30px',
                      px:2,
                      height:50,
                      ':hover': { bgcolor: 'black', color: 'white' },
                    }}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Start Free Today
                  </Button>
                  <br/>
                  <br/>

                        <Typography variant="h6" component="p" sx={{ m: 1 }}>
                            YOU ARE IN CONTROL.<br/>
                            ULTRA FLEXIBILITY IN LEARNING. <br/>
                            Study from anywhere.<br/>
                            TAKE THE NEXT STEPS IN YOUR JOURNEY TO QUALIFICATION.<br/>
                            And take your exams whenever you're ready. 
                        </Typography>
                        <Grid container spacing={4} justifyContent="center">
                
                {/* WhatsApp Feature */}
                <Grid item xs={12} md={4} textAlign="center">
                    <WhatsAppIcon sx={{ fontSize: 80, color: '#004AAD' }} />
                    <Typography variant="h6" component="p" sx={{ mt: 2, color:'white' }}>
                        Access to a 24/7 WhatsApp group chat
                    </Typography>
                </Grid>
                
                {/* Savings Feature */}
                <Grid item xs={12} md={4} textAlign="center">
                    <SavingsIcon sx={{ fontSize: 80, color: '#004AAD' }} />
                    <Typography variant="h6" component="p" sx={{ mt: 2, color:'white' }}>
                        Affordable
                    </Typography>
                </Grid>
                
                {/* Flexibility Feature */}
                <Grid item xs={12} md={4} textAlign="center">
                    <EventAvailableIcon sx={{ fontSize: 80, color: '#004AAD' }} />
                    <Typography variant="h6" component="p" sx={{ mt: 2, color:'white' }}>
                        Flexible
                    </Typography>
                </Grid>
                
            </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    <br/>
                    <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                            component="img"
                            src={girlStudyingPolygon}
                            alt="Girl Studying"
                            sx={{
                                width: '90%',
                                maxWidth: 900,
                                maxHeight:'100%',
                                borderRadius: 2,
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>

            {/* Section 2 */}
            <Box sx={{ bgcolor: '#0F1214', py: 4 }}>
            <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', m: 2, color:'white' }}>
                            <Divider sx={{borderBottomWidth: '5px',  color:'white'}}>Customer Reviews</Divider>
                        </Typography>
                <Grid container spacing={3} justifyContent="center">
                    {/* Review Card 1 */}
                    <Grid item xs={12} md={5}>
                        <Card sx={{ bgcolor: '#14181B', color:'white', p: 1, m:1}}>
                            <CardContent>
                                <FormatQuoteIcon sx={{ fontSize: 90, color: '#004AAD' }} />
                                <Typography variant="body1" component="p" sx={{ mt: 1 }}>
                                    “I want to extend my heartfelt thanks to Law School Compass for their exceptional SQE preparation
                                    material and support. The comprehensive resources, structured study plans, and insightful guidance
                                    have been instrumental in my preparation journey.”
                                </Typography>
                                <Typography variant="body2" component="p" sx={{ mt: 2, fontWeight: 'bold' }}>
                                    - Sasmita
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Review Card 2 */}
                    <Grid item xs={12} md={5}>
                        <Card sx={{ bgcolor: '#14181B', color:'white', p: 1, m:1 }}>
                            <CardContent>
                                <FormatQuoteIcon sx={{ fontSize: 90, color: '#004AAD' }} />
                                <Typography variant="body1" component="p" sx={{ mt: 1 }}>
                                    “Law School Compass is building a great community for those sitting the SQE to support one another
                                    and make the process far less daunting and lonely. I’ve read a few of these resources, and they are
                                    really good. I will definitely use them for my SQE preparation.”
                                </Typography>
                                <Typography variant="body2" component="p" sx={{ mt: 2, fontWeight: 'bold' }}>
                                    - Maab (@lifewithmaab on insta)
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
          <VoiceflowChat/>
        </Box>

</div>
  );
}


export default Home;
