import React from 'react';
import { Box, Typography, Table, TableBody,TableHead, TableCell, TableRow, Grid, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import studyLibraryImage from './studying_at_library.jpg'; // Replace with the actual path to the image
import RevampNavBar from './RevampNavBar';

// Styled component for the background image
const BackgroundImageSection = styled(Box)({
    backgroundImage: `linear-gradient(rgba(0, 74, 173, 1), rgba(0, 74, 173, 1), rgba(0, 74, 173, 1),rgba(0, 74, 173, 0.8),rgba(0, 74, 173, 0.5),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0), transparent), url(${studyLibraryImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
    padding: '40px 20px',
    position: 'relative',
    height:'90vh',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 74, 173, 0)', // Dark overlay for better readability
    },
});

const SQE2 = () => {
    return (
        <Box sx={{ bgcolor: '#004AAD', color: 'white', minHeight: '100vh', }}>
            <RevampNavBar/>
            <BackgroundImageSection>
            {/* Header and Description */}
            <Typography variant="h3" component="h1" textAlign="center" gutterBottom sx={{fontWeight: 'bold'}}>
                SQE2
            </Typography>
            <Typography variant="h6" textAlign="center" sx={{ mb: 4, ml:3, mr:3 }}>
                Coming Soon...
            </Typography>

            {/* Table Section */}
            </BackgroundImageSection>
        </Box>
    );
};

export default SQE2;
