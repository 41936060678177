import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'; // Importing RouterLink for navigation
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  return (
    <Container maxWidth="md" sx={{marginBottom:'8px'}}>
      <Typography variant="h3" component="h1" textAlign="center" gutterBottom sx={{fontWeight: 'bold'}}>
        FAQ
      </Typography>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">What is the Solicitors Qualification Exam?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The Solicitors Qualification Exam (SQE) is the new form of assessment put forward by the Solicitor’s
            Regulatory Authority. It consists of two assessments, called Functioning legal knowledge assessments (FLK 1
            and FLK 2). Each exam consists of 180 questions and is sat in a Person test center.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">What is the Law School Compass?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           We are a private tuition company which aims to provide accessible tutoring at an affordable price.
            The main point being, we’ve been in the position you are in so we know how to prepare for your SQE1
            exams.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Is Law School Compass right for me?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          If you're preparing for the SQE (whether SQE1 or SQE2), we're here to support you. Whether you're studying independently or enrolled with a course provider, our materials are one-of-a-kind—carefully crafted to provide you with unique learning tools. We’re not your typical teachers or professors. We are ready to help you not only succeed but also enjoy the journey to conquering the SQE          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">What is QWE</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Qualifying Work Experience (QWE)is a key requirement for becoming a solicitor in England and Wales under the Solicitors Qualifying Examination (SQE) route. It involves gaining practical legal experience in one or more organisations for a minimum of two years. The experience can be completed at law firms, in-house legal teams, or even through pro bono work, as long as it provides exposure to legal practice and is signed off by an authorized solicitor. Unlike traditional training contracts, QWE offers more flexibility.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">What is your cancellation policy for tuition services?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We expect clients to cancel or reschedule their sessions with at least 48 hours notice. Sessions
            which are canceled with less than 48 hours notice may not be eligible for a refund. Terms and conditions
            apply.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">What are your mocks?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We offer both virtual and in person SQE mocks as part of our tuition services.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">How do I contact you?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can contact us via email at <a href='mailto:info.admin@lawschoolcompass.com'>info.admin@lawschoolcompass.com</a>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default FAQ;
