import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from '@mui/material/Grid';

// const baseUrl = process.env.PUBLIC_URL;

const images = [
    {
      url: "https://blog-resources2.onthemarket.com/wp-content/uploads/2015/07/Solicitors.gif",
      title: "What is SQE?",
      caption: "Disecting what SQE involves, and how does it differ from the previous qualification routes",
    },
    {
      url: "https://static.vecteezy.com/system/resources/previews/031/553/320/large_2x/an-inspiring-image-of-students-wearing-graduation-caps-and-gowns-looking-towards-the-future-with-hope-and-determination-symbolizing-the-ultimate-goal-of-education-generative-ai-photo.jpeg",
      title: "What is LLB?",
      caption: "Discover what a Bachelor of Law entails",
    },
    {
      url: "https://www.thetimes.co.uk/imageserver/image/%2Fmethode%2Ftimes%2Fprod%2Fweb%2Fbin%2Fd17bf85f-206b-49d6-83b0-8681552c5b1e.jpg?crop=5000%2C2813%2C0%2C260&resize=1200",
      title: "How to prepare for SQE",
      caption: "Tips on learning the main areas that are covered in the SQE assessments",
    },
    {
      url: "https://www.legalcheek.com/wp-content/uploads/2019/03/Library.jpg",
      title:"Maters? PhD? Careers? What's to come after obtaining an LLB",
      caption: "What career prospects are there, and should you pursue further studies",
    },
  ];
  
  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    height: 210,
    border: "4px solid white",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "80%" ,
      height: 200,
    },
    "&:hover, &.Mui-focusVisible": {
        "& .MuiGrid-root":{textAlign: 'center', alignItems: 'center', display: 'flex', justifyContent: 'center'},
      "& .MuiImageBackdrop-root": {
        opacity: 0.9,
        backgroundColor: "#004AAD",
        
      },
    },
  }));
  
  const ImageSrc = styled("span")({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 50%",
    borderRadius: "4px",
    textAlign: 'center',
    alignItems: 'center',
  });
  
  const Image = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
    borderRadius: "4px",
  }));
  
  const ImageBackdrop = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.45,
    transition: theme.transitions.create("opacity"),
    borderRadius: "4px",
  }));
  
  

export default function Articles() {
  return (
    <div style={{}}>
              <div class="text-center">
              <Typography gutterBottom variant="h4" component="div">Articles</Typography>
</div>
          <Divider />
          <br/>
          <Box style={{textAlign: 'center', alignItems: 'center',}}>
      {images.map((image) => (
        <ImageButton
          focusRipple
          key={image.title}
          style={{
            width: "90%",
          }}
          sx={{ borderRadius: "4px" }}
        >
          <ImageSrc
            style={{
              backgroundImage: `url(${image.url})`,
              borderRadius: "4px",
              
            }}
          />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
          <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: 'center', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Typography
            variant="h6"
              component="span"
              color="inherit"
              sx={{
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                
              }}
              style={{textAlign: 'center',}}
            >
              {image.title}
            </Typography>
            </Grid>
            <Grid classname='caption' item xs={12} sm={12} md={12} lg={12} display='none'>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
            >
              {image.caption}
            </Typography>
            </Grid>
            </Grid>
          </Image>
        </ImageButton>
      ))}
    </Box>


    </div>
  );
}
